import {LitElement, css, html} from 'lit'
import {customElement, property} from 'lit/decorators.js'
import '@material/web/dialog/dialog.js'
import {MdDialog} from '@material/web/dialog/dialog.js'
import '@material/web/fab/fab.js'
import '@material/web/radio/radio.js'
import './components/layout-card.ts'
// import './components/ui-list.ts'
// import {createContext, provide} from '@lit/context'
// import {consume} from '@lit/context'
// import {BehaviorSubject} from 'rxjs'


declare global {
    interface HTMLElementTagNameMap {
        'shopping-list': ShoppingList
    }
}

export class ShoppingListEntry {
    // @property({type: String})
    key: string = crypto.randomUUID()
    // @property({type: String})
    description: string
    // @property({type: Number})
    quantity: number | null

    constructor(description: string, quantity: number | null) {
        this.description = description
        this.quantity = quantity
    }

    equals(key: string) {
        return key == this.key
    }
}

/**
 * A generic list element. With replaceable editor (default maintain list of strings).
 *
 * @slot list-search
 * @csspart list-search
 * @slot list-editor
 * @csspart list-editor
 */
@customElement("shopping-list")
export class ShoppingList extends LitElement {

    @property({type: String})
    title: string = 'Grocery List'
    @property({state: true, attribute: false, noAccessor: true})
    _listData: Array<ShoppingListEntry> = []
    @property({state: true, attribute: false, noAccessor: true})
    _editor: MdDialog | null = null
    @property({state: true, attribute: false})
    _formData: ShoppingListEntry | null = null

    constructor() {
        super()
        this._loadFromStorage()
    }

    // connectedCallback() {
    //     super.connectedCallback()
    //     // for a generic list editor, request list from context (provided by MyLists)
    //     // if require changes of shared context
    //     //     this._subscription = subject.subscribe(this._onStateChange.bind(this))
    // }

    // disconnectedCallback() {
    //     super.disconnectedCallback()
    //     if (this._subscription) {
    //         this._subscription.unsubscribe()
    //     }
    // }

    firstUpdated() {
        this._editor = <MdDialog>(<ShadowRoot>this.renderRoot).getElementById('list-editor-dialog')
        if (this._editor == null) {
            throw "Editor was not found. Ensure slotted content has id of 'list-editor-dialog'."
        }
    }

    /** Load an existing shopping list if found in the browser's local storage. */
    _loadFromStorage() {
        const stored_list = localStorage.getItem(this.title)
        if (stored_list === null) {
            this._listData = []
        } else {
            const result: Array<ShoppingListEntry> = []
            const a = JSON.parse(stored_list)
            if (a instanceof Array)
                a.map((i) => {
                    result.push(new ShoppingListEntry(i.description, i.quantity))
                })
            this._listData = result
        }
    }

    /** Save the current shopping list to the browser's local storage. */
    _persistToStorage() {
        localStorage.setItem(this.title, JSON.stringify(this._listData))
    }

    render() {
        /*
                    <slot name="list-search">
                        <search part="list-search">
                            <input type="search" placeholder="search existing...">
                            <!-- <form>
                                <label for="filter-all">All</label>
                                <md-radio id="filter-all" name="filter" value="All" checked></md-radio>
                                <label for="filter-outstanding">Outstanding</label>
                                <md-radio name="filter-outstanding" value="Outstanding"></md-radio>
                                <label for="filter-completed">Completed</label>
                                <md-radio name="filter-completed" value="Completed"></md-radio>
                            </form> -->
                        </search>
                    </slot>
        */
        return html`
            <layout-card>

                <hgroup slot="card-header">
                    <h2>
                        <img src="/media/list-icon.svg" class="header-icon svg-color-grey" alt="shopping list icon"/>
                        <span>${this.title}</span>
                    </h2>
                </hgroup>

                <div slot="card-content">
                    <md-fab id="add-new" size="small" aria-label="Add New Item" title="Add New Item" @click="${this._editNew}">
                        <img slot="icon" src="media/add.svg" width="35" height="35" alt="Add"/>
                    </md-fab>
                    <ol class="content" title="${this.title}">
                        ${this._listData.map(listItem => {
            return html`
                                <li>
                                    <!-- <input type="image" src="media/minus-circle.svg" value="$ {listItem.key}" @click="$ {this.decreaseQty}" alt="decrease quantity" width="25" height="25" /> -->
                                    <span>${(listItem.quantity && listItem.quantity > 0 ? listItem.quantity + ' ' : '') + listItem.description}</span>
                                    <!-- <input type="image" src="media/plus-circle.svg" value="$ {listItem.key}" @click="$ {this.increaseQty}" alt="increase quantity" width="25" height="25" /> -->

                                    <input type="image" src="media/edit-circle.svg" value="${listItem.key}" @click="${this._editExisting}" alt="edit item" width="25" height="25" tabindex="-1"/>
                                    <input type="image" src="media/delete.svg" value="${listItem.key}" @click="${this._deleteExisting}" alt="edit item" width="25" height="25" tabindex="-1"/>
                                </li>
                            `
        })}
                    </ol>
                </div>

            </layout-card>

            <md-dialog id="list-editor-dialog" part="list-editor" aria-label="list item editor">
                <div slot="content">
                    <slot name="list-editor">
                        <form id="list-editor-form" method="dialog">
                            <!-- default generic list item editor -->
                            <fieldset>
                                <legend class="user-hints">Requested item <wbr>for the next shop</legend>
                                <label for="description">Item </label>
                                <input type="text" autofocus id="description" name="description"
                                    value="${this._formData?.description ? this._formData.description : ''}" placeholder="" title="name of required item"/>
                                <label for="quantity">Qty </label>
                                <input type="number" id="quantity" name="quantity"
                                    value="${this._formData?.quantity ? this._formData?.quantity : ''}" placeholder="" min="1" max="1000" title="preferred quantity"/>
                            </fieldset>
                        </form>
                    </slot>
                </div>
                <div slot="actions">
                    <input form="list-editor-form" type="button" value="Cancel" @click="${this.closeEditor}"/>
                    <input form="list-editor-form" type="submit" value="Save" @click="${this._commitEdit}"/>
                </div>
            </md-dialog>

            <!-- <template> -->
            <svg name="svg-filters" style="display:none;">
                <filter id="svg-color-grey">
                    <feflood flood-color="#696969" flood-opacity="1" result="FLOOD"/>
                    <feComposite in="FLOOD" in2="SourceGraphic" operator="in"></feComposite>
                </filter>
            </svg>
            <!-- </template> -->
        `
    }

    _editNew(_event: any) {
        this._formData = null
        if (this._editor) {
            this._editor.show()
        } else {
            throw "Editor was not found. Ensure slotted content has id of 'list-editor-dialog'."
        }
    }

    _editExisting(event: any) {
        // find associated data element in list
        this._formData = this._listData.find(li => li.equals(event.target.value)) || null
        if (!this._formData) {
            console.error('Could not find item!', event.target.value)
            return
        }
        // display editor
        if (this._editor) {
            this._editor.show()
        } else {
            throw "Editor was not found. Ensure slotted content has id of 'list-editor-dialog'."
        }
    }

    _deleteExisting(event: any) {
        // find associated data element in list
        const index = this._listData.findIndex(li => li.equals(event.target.value))
        if (index == -1) {
            console.error('Could not find item!', event.target.value)
            return
        }
        this._listData.splice(index, 1)
        this._persistToStorage()
        this.requestUpdate()
    }

    _commitEdit(event: any) {  // when editing  ( >> auto commit, unless cancel; if so, still include icon? )
        event.preventDefault()
        // get available form values
        // const frmData = new FormData(event.target)
        const frm = <HTMLFormElement>(<ShadowRoot>this.renderRoot).getElementById('list-editor-form')
        if (!frm) {
            throw "Editor form was not found. Ensure slotted content has a form with an id of 'list-editor-form'."
        }
        const frmData = new FormData(frm)
        // convert to an object
        const data: Record<string, FormDataEntryValue> = {}
        for (let [key, value] of frmData.entries()) {
            data[key] = value
        }
        // if no description is provided, then ignore silently (consider a cancel)
        if (data.description) {
            // validate quantity
            if (isNaN(<any>data.quantity)) {
                alert(`If provided, quantity must be a number: ${data.quantity}`)
                return
            }
            // update or add new
            if (this._formData) {
                // then editing an existing item in list
                this._formData.description = String(data.description)
                this._formData.quantity = Number(data.quantity)
            } else {
                const newItem = new ShoppingListEntry(String(data.description), Number(data.quantity))
                this._listData.unshift(newItem)
            }
            this._persistToStorage()
            this.requestUpdate()
        }

        frm.reset()
        this._formData = null
        this.closeEditor()
        // this.renderRoot.getElementById("search").focus()

        // const event = new Event('shopping-list-updated', { bubbles: true, composed: true })
        // MyList.dispatchEvent(event)
    }

    closeEditor() {
        if (this._editor) {
            this._editor.close()
        } else {
            throw "Editor was not found. Ensure slotted content has id of 'list-editor-dialog'."
        }
    }

    // increaseQty(event: any) {
    //     console.info(event.target.value)
    //     // find associated data element in list
    //     const request = this._listData.find(li => li.equals(event.target.value)) || null
    //     if (!request) {
    //         console.error('Could not find item!', event.target.value)
    //         return
    //     } else if (request.quantity) {
    //         ++request.quantity
    //     } else {
    //         request.quantity = 1
    //     }
    //     this._persistToStorage()
    //     this.requestUpdate()

    //     // const event = new Event('shopping-list-updated', { bubbles: true, composed: true })
    //     // MyList.dispatchEvent(event)
    // }

    // decreaseQty(event: any) {
    //     // find associated data element in list
    //     const request = this._listData.find(li => li.equals(event.target.value)) || null
    //     if (!request) {
    //         console.error('Could not find item!', event.target.value)
    //         return
    //     } else if (request.quantity) {
    //         --request.quantity
    //         this._persistToStorage()
    //         this.requestUpdate()
    //     }

    //     // const event = new Event('shopping-list-updated', { bubbles: true, composed: true })
    //     // MyList.dispatchEvent(event)
    // }

    static get styles() {
        return [
            css`
            :host {
                display: block;
                margin: 0 auto;
                margin-bottom: 5em;
            }

            .svg-invert-color {
                filter: invert(100%);
            }

            .svg-color-grey {
                /*
                filter: url('/media/svg-filters.svg#svg-color-grey');
                */
                filter: url('#svg-color-grey');
            }

            @media screen and (prefers-color-scheme: light) {
                a:hover {
                    color: #747bff;
                }
                button {
                    background-color: #f9f9f9;
                }
            }
            `,
            // layout card
            css`
            layout-card {
                margin: 0;
                padding: 0;
                text-align: left;
            }

            layout-card > hgroup {
                margin: 0;
                margin-top: 1em;

                h2 {
                    margin: 0;
                    margin-left: 0.5em;
                    text-align: left;
                    vertical-align: middle;
                }

                h2 > img {
                    width: 3em;
                    height: 3em;
                    margin-right: 0.5em;
                    vertical-align: middle;
                }
            }

            layout-card div[slot=card-content] {
                margin-top: 0.5em;
                min-width: 12em;
                max-width: 35em;
            }

            md-fab {
                float: right;
                margin-top: -3em;
            }
            `,
            // search
            css`
            search {
                margin-left: -3em;
                padding: 2px;
                display: inline;
                color: #555;
                vertical-align: bottom;

                input[type="search"] {
                    margin: 0;
                    padding: 9px 4px 9px 40px;
                    border: 1px solid currentColor;
                    border-radius: 5px;
                    color: inherit;
                    font-size: 14px;
                    background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat 13px center;
                }
                input[type="search"]::placeholder {
                    color: #bbb;
                }

                form {
                    display: none;
                    margin-top: 0.5em;
                    margin-left: -3em;

                    md-radio {
                        margin-left: 1.5em;
                    }
                }
            }
            `,
            // list items
            css`
            ol, ul {
                margin: 0;
                padding-left: 0.8em;
            }

            li {
                margin: 0;
                margin-top: 0.2em;
                /* list-style-type: circle; */
                list-style-type: none;
                text-align: left;
                vertical-align: top;
                /* font-family: 'Brush Script MT', cursive; */
                font-family: Verdana, Geneva, Tahoma, sans-serif;
            }

            @media (pointer:none), (pointer:coarse) {
                li {
                    font-size: 1.3em;
                }
            }

            li input[type=image] {
                vertical-align: text-bottom;
                margin-left: 1em;
            }

            li input[type=image]:first-of-type {
                margin-left: 2em;
            }
            `,
            // editor
            css`
        `
        ]
    }
}
