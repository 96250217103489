import {LitElement, css, html} from 'lit'
import {customElement} from 'lit/decorators.js'

/**
 * Visually styled container for a single entry. A simple three part card layout element.
 *
 * @slot card-header -
 * @slot card-content -
 * @slot card-footer -
**/
@customElement("layout-card")
export class UiCard extends LitElement {

    // @property({type: String})
    // backgroundImgPath: string = ''

    render() {
        return html`
            <!-- <img src = "$ {this.backgroundImgPath}" alt="card background"/> -->
            <div class="layout">
                <slot name="card-header" class="header"></slot>
            </div>
            <div class="layout">
                <slot name="card-content" class="content"></slot>
            </div>
            <div class="layout">
                <slot name="card-footer" class="footer"></slot>
            </div>
        `
    }

    static get styles() {
        return css`
            /*
            :host {}
            */

            /*
            div.layout {
                display: flex;
                justify-content: center;
            }

            ::slotted(*) {
                color: red;
            }
            ::slotted(.header) {
                color: red;
            }
            slot[name=card-header] {
                color: red;
            }
            */

            /*
            @media (prefers-color-scheme: light) {
                a:hover {
                    color: #747bff;
                }
                button {
                    background-color: #f9f9f9;
                }
            }
            */
        `
    }
}
